<template>

  <b-card

    :bg-variant="bg"
    :to="to"
    text-variant="white"

    @click="$emit('click')"
  >
    <b-card-body style="padding: 0.5rem;">
      <b-link
        :to="to"
        @click="$emit('click')"
      >
        <h5 class="mb-1 mt-50 text-white text-center">
          {{ title }}
        </h5>
        <b-card-text>
          <h2 class="text-white text-center">
            {{ description }}
          </h2>

        </b-card-text>
      </b-link>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardText, BLink, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BLink,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
    }
  },
}
</script>
