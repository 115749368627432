<template>
  <div v-if="statisticData">
    <section id="dashboard-ecommerce" />
    <b-alert
      variant="primary"
      show
    >
      <b-link
        v-if="$user().subscription.without_payment"
        :to="{ name: 'subscription' }"
      >
        <div class="alert-body">

          <span><strong>Upgrade your package to enjoy advanced features</strong> <strong class="text-"> BUY
            NOW</strong></span>
        </div>
      </b-link>
    </b-alert>

    <b-row>
      <upgrade-plan-modal ref="refUpgradePlanModal" />
      <b-col
        md="8"
        lg="6"
      >
        <b-col
          md="12"
          lg="12"
        >

          <b-alert
            v-if="!$userHandler.checkAppUsageStep('business_name_entered') && true==false"
            v-height-fade
            show
            dismissible
            fade
            :variant="$userHandler.checkAppUsageStep('business_name_entered') ? 'success' : 'warning'"
          >
            <b-link
              :to="{ path: '/settings#company-info' }"
              router-tag="div"
              style="cursor:pointer;"
            >
              <!--
              <div class="alert-body">

                <feather-icon
                  class="mr-25"
                  :icon="$userHandler.checkAppUsageStep('business_name_entered')?'CheckIcon':'XIcon'"
                />
                <span>Add your business name</span>

              </div>
            -->
            </b-link>
          </b-alert>

          <b-alert
            v-if="!$userHandler.checkAppUsageStep('business_info_entered')&& true==false"
            v-height-fade
            show
            dismissible
            fade
            :variant="$userHandler.checkAppUsageStep('business_info_entered') ? 'success' : 'warning'"
          >
            <b-link
              :to="{ path: '/settings#company-info' }"
              router-tag="div"
              style="cursor:pointer;"
            >
              <!--
              <div class="alert-body">
                <feather-icon class="mr-25"
                  :icon="$userHandler.checkAppUsageStep('business_info_entered') ? 'CheckIcon' : 'XIcon'" />
                <span>Enter your business info</span>
              </div>
              -->
            </b-link>
          </b-alert>

          <b-alert
            v-if="!$userHandler.checkAppUsageStep('check_tax_details') && true==false"
            v-height-fade
            show
            dismissible
            fade
            :variant="$userHandler.checkAppUsageStep('check_tax_details') ? 'success' : 'warning'"
          >
            <b-link
              :to="{ path: '/settings#tax-and-currency' }"
              router-tag="div"
              style="cursor:pointer;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  :icon="$userHandler.checkAppUsageStep('check_tax_details') ? 'CheckIcon' : 'XIcon'"
                />
                <span>Check your tax details</span>
              </div>
            </b-link>
          </b-alert>

          <b-alert
            v-if="!$userHandler.checkAppUsageStep('customize_invoice_design')&& true==false"
            v-height-fade
            show
            dismissible
            fade
            :variant="$userHandler.checkAppUsageStep('customize_invoice_design') ? 'success' : 'warning'"
          >
            <b-link
              router-tag="div"
              style="cursor:pointer;"
              :exact="true"
              :active="true"
              :to="{ name: 'settings-invoice-design' }"
            >
              <!--
                <div class="alert-body">
                <feather-icon class="mr-25"
                  :icon="$userHandler.checkAppUsageStep('customize_invoice_design') ? 'CheckIcon' : 'XIcon'" />
                <span>Customize your invoice design</span>
              </div>
            -->
            </b-link>
          </b-alert>
        </b-col>

        <b-col
          v-if="!$userHandler.checkAppUsageStep('first_invoice_created') && true==false"
          md="12"
          lg="12"
        >
          <b-card-actions
            class="alert-card"
            action-close
          >
            <b-card-title class="d-flex">

              <feather-icon
                icon="FileTextIcon"
                size="60"
              />

              <span class="ml-3 text-justify">Create your first invoice in a few simple steps</span>

            </b-card-title>
            <b-card-header class="d-flex justify-content-end">
              <b-link
                class="font-weight-bolder"
                @click="handleCreateInvoiceButton"
              >
                Try it now
              </b-link>
            </b-card-header>
          </b-card-actions>
        </b-col>

      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <button-card
          :description="'Create New Invoice'"
          bg="success"
          @click="handleCreateInvoiceButton"
        />
      </b-col>
      <b-col md="12">
        <h1>Overview</h1>
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="$moment.months(statisticData.this_month_sales.month_number - 1).toUpperCase() + ' SALES'"
          :description="statisticData.currency_code + ' ' + $staticParams.formatCurrency(statisticData.this_month_sales.total)"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="'OVERDUE INVOICES ' + '(' + statisticData.overdue_invoices.count + ')'"
          :description="statisticData.currency_code + ' ' + $staticParams.formatCurrency(statisticData.overdue_invoices.total)"
          bg="danger"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="'UNPAID INVOICES ' + '(' + statisticData.unpaid_invoices.count + ')'"
          :description="statisticData.currency_code + ' ' + $staticParams.formatCurrency(statisticData.unpaid_invoices.total)"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="'UNSENT INVOICES ' + '(' + statisticData.unsent_invoices.count + ')'"
          :description="statisticData.currency_code + ' ' + $staticParams.formatCurrency(statisticData.unsent_invoices.total)"
        />
      </b-col>
      <b-col md="12">
        <h1>Current Year</h1>
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="'INVOICES COUNT'"
          :description="statisticData.this_year_sales.count"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'invoices' }"
          :title="'TOTAL AMOUNT'"
          :description="statisticData.currency_code + ' ' + $staticParams.formatCurrency(statisticData.this_year_sales.total)"
          bg="primary"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'clients' }"
          :title="'TOTAL CUSTOMERS'"
          :description="statisticData.this_year_clients.count"
        />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <button-card
          :to="{ name: 'products' }"
          :title="'TOTAL PRODUCTS'"
          :description="statisticData.this_year_products.count"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import upgradePlanModal from '@core/components/information-components/UpgradePlanModal.vue'
import dashboardStoreModule from './dashboardStoreModule'
import ButtonCard from './ButtonCard.vue'

export default {
  components: {
    BCardHeader,
    BRow,
    BCardTitle,
    upgradePlanModal,
    BLink,
    BCardActions,
    BCol,
    BAlert,
    ButtonCard,
  },
  data() {
    return {
      statisticData: null,
    }
  },
  created() {
    this.fetchStatistics()
  },
  methods: {
    handleCreateInvoiceButton() {
      const { limits } = this.$user()

      let access = true
      if (limits.remaining_invoice_limit != null) {
        if (limits.remaining_invoice_limit <= 0) {
          access = false
          this.$refs.refUpgradePlanModal.show()
        }
      }

      if (access) {
        this.$destroy()
        this.$router.replace({ name: 'invoices-add' })
      }
    },
    fetchStatistics() {
      store
        .dispatch('app-dashboard/fetchStatistics')//
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.statisticData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return {}
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.alert-card .card,
.alert-card .card-header {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
</style>
